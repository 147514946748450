import { type FC, useMemo } from 'react';

import { clientCssVars } from '@/util/style';
import { useGlobalStore } from '@/store/context';
import { CozeIframeMobile } from '@/components/coze-iframe/mobile';
import WidgetMobilePng from '@/assets/widget.png';

import { type CozeWidgetProps } from '.';

import styles from './index.module.less';

export const CozeWidgetMobile: FC<CozeWidgetProps> = ({
  position = 'fixed',
  client,
}) => {
  const { chatVisible, setChatVisible, iframeLoaded } = useGlobalStore(s => ({
    chatVisible: s.chatVisible,
    setChatVisible: s.setChatVisible,
    iframeLoaded: s.iframeLoaded,
    imagePreviewVisible: s.imagePreview.visible,
    imagePreviewUrl: s.imagePreview.url,
    setImagePreview: s.setImagePreview,
  }));
  const componentProps = client?.options?.componentProps;
  const iconUrl = componentProps?.icon;
  const zIndex = componentProps?.zIndex;

  const showIframe = chatVisible || iframeLoaded;

  const cssVars = useMemo(() => {
    if (typeof zIndex === 'number') {
      clientCssVars['--coze-z-index-iframe'] = zIndex;
    }

    return clientCssVars;
  }, [zIndex]);

  return (
    <>
      {showIframe && (
        <CozeIframeMobile
          style={{
            display: chatVisible ? 'block' : 'none',
            ...cssVars,
          }}
          onClose={() => setChatVisible(false)}
          iframeParamsSearch={client.iframeParamsSearch}
        />
      )}
      {!chatVisible && (
        <div
          style={{ position, ...cssVars }}
          className={styles.cozeChatWidgetMobile}
          onClick={e => {
            e.stopPropagation();
            setChatVisible(true);
          }}
        >
          <img alt="logo" src={iconUrl || WidgetMobilePng} />
        </div>
      )}
    </>
  );
};
