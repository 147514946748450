// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../common/temp/node_modules/.pnpm/css-loader@6.10.0_@rspack+core@0.6.0_webpack@5.89.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../common/temp/node_modules/.pnpm/css-loader@6.10.0_@rspack+core@0.6.0_webpack@5.89.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".a7f45cc10dab9fe1cc84:not(.semi-button-with-icon-only) {\n  min-width: 96px;\n}\n.bc51fcac3d75ff875166.ad34ec78996698ccd335 {\n  border-radius: 6px;\n}\n.bc51fcac3d75ff875166.ad34ec78996698ccd335 .semi-button-content-right {\n  margin-left: 4px;\n}\n.bc51fcac3d75ff875166.d850da45bed8c39e100e {\n  border-radius: 8px;\n}\n.bc51fcac3d75ff875166.d850da45bed8c39e100e .semi-button-content-right {\n  margin-left: 8px;\n}\n", "",{"version":3,"sources":["webpack://./../../components/bot-semi/src/components/ui-button/index.module.less"],"names":[],"mappings":"AAAA;EACE,eAAA;AACF;AAEA;EACE,kBAAA;AAAF;AADA;EAKM,gBAAA;AADN;AAMA;EACE,kBAAA;AAJF;AAGA;EAKM,gBAAA;AALN","sourcesContent":[".button-min-width:not(:global(.semi-button-with-icon-only)) {\n  min-width: 96px;\n}\n\n.button.button-size-small {\n  border-radius: 6px;\n\n  :global {\n    .semi-button-content-right {\n      margin-left: 4px;\n    }\n  }\n}\n\n.button.button-size-default {\n  border-radius: 8px;\n\n  :global {\n    .semi-button-content-right {\n      margin-left: 8px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button-min-width": "a7f45cc10dab9fe1cc84",
	"buttonMinWidth": "a7f45cc10dab9fe1cc84",
	"button": "bc51fcac3d75ff875166",
	"button-size-small": "ad34ec78996698ccd335",
	"buttonSizeSmall": "ad34ec78996698ccd335",
	"button-size-default": "d850da45bed8c39e100e",
	"buttonSizeDefault": "d850da45bed8c39e100e"
};
export default ___CSS_LOADER_EXPORT___;
