import { devtools, subscribeWithSelector } from 'zustand/middleware';
import { create } from 'zustand';

import { isDevAlias } from '@/util/env';
import { type Layout } from '@/types/client';
import { type WebChatClient } from '@/client';

export type Lang = 'zh' | 'en';

interface IframeState {
  lang: Lang;
  layout: Layout;
  readonly client: WebChatClient;
}

interface IframeAction {
  setLang: (lang: Lang) => void;
}

export type IframeStateAction = IframeState & IframeAction;

export const createIframeStore = (client: WebChatClient) => {
  const { options } = client;
  const { layout } = options?.componentProps ?? {};

  const defaultState: IframeState = {
    lang: 'zh',
    client,
    layout,
  };

  return create<IframeStateAction>()(
    devtools(
      subscribeWithSelector((set, get) => ({
        ...defaultState,
        setLang: lang => {
          set({
            lang,
          });
        },
      })),
      {
        enabled: isDevAlias,
        name: 'sdkChatApp.iframe',
      },
    ),
  );
};
