import { type FC, useEffect, useRef } from 'react';

import cs from 'classnames';
import { UIButton, Spin } from '@flow-infra/bot-semi';
import { IconClose } from '@douyinfe/semi-icons';

import { useGlobalStore } from '@/store/context';

import { getUrlProtocol } from '../util';
import { type CozeIframeProps } from '..';

import styles from './index.module.less';

export const CozeIframeMobile: FC<CozeIframeProps> = ({
  style,
  className,
  onClose,
  iframeParamsSearch,
}) => {
  const iframeRef = useRef<HTMLIFrameElement>();
  const { setIframe, setIframeLoaded, iframeLoaded } = useGlobalStore(s => ({
    setIframe: s.setIframe,
    setIframeLoaded: s.setIframeLoaded,
    iframeLoaded: s.iframeLoaded,
  }));

  useEffect(() => {
    setIframe(iframeRef.current);
  }, []);

  return (
    <div className={cs(styles.iframeWrapperMobile, className)} style={style}>
      {!iframeLoaded && (
        <Spin spinning size="large" wrapperClassName={styles.loading} />
      )}
      <UIButton
        className={styles.closeBtn}
        icon={<IconClose size="large" />}
        theme="borderless"
        type="tertiary"
        onClick={() => onClose?.()}
      />
      <iframe
        style={{ display: iframeLoaded ? 'block' : 'none' }}
        className={styles.cozeIframe}
        src={getUrlProtocol(iframeParamsSearch)}
        ref={iframeRef}
        onLoad={() => {
          setIframeLoaded(true);
        }}
      />
    </div>
  );
};
