// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../common/temp/node_modules/.pnpm/css-loader@6.10.0_@rspack+core@0.6.0_webpack@5.89.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../common/temp/node_modules/.pnpm/css-loader@6.10.0_@rspack+core@0.6.0_webpack@5.89.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".fe3173ed6eda3342aedf {\n  position: fixed;\n  height: calc(100% - 40px);\n  min-height: 400px;\n  max-height: 1200px;\n  bottom: 20px;\n  right: 20px;\n  z-index: calc(var(--coze-z-index-iframe) + 1);\n  width: 460px;\n  background-color: #fff;\n  border-radius: 8px;\n  box-shadow: 0 6px 8px 0 rgba(29, 28, 35, 0.06), 0 0 2px 0 rgba(29, 28, 35, 0.18);\n}\n.fe3173ed6eda3342aedf .f9fba408aa15aa07be96 {\n  position: absolute;\n  top: 200px;\n  left: 50%;\n  transform: translateX(-50%);\n}\n.fe3173ed6eda3342aedf .eb27e8d345a6df189413 {\n  width: 100%;\n  height: 100%;\n  background: #fff;\n  border: none;\n  border-radius: 8px;\n}\n.fe3173ed6eda3342aedf .d8ba2c365ec31bc5789a {\n  position: absolute;\n  right: 16px;\n  top: 16px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/coze-iframe/index.module.less"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,yBAAA;EACA,iBAAA;EACA,kBAAA;EACA,YAAA;EACA,WAAA;EACA,6CAAA;EACA,YAAA;EACA,sBAAA;EACA,kBAAA;EACA,gFAAA;AACF;AAZA;EAcI,kBAAA;EACA,UAAA;EACA,SAAA;EACA,2BAAA;AACJ;AAlBA;EAqBI,WAAA;EACA,YAAA;EACA,gBAAA;EACA,YAAA;EACA,kBAAA;AAAJ;AAzBA;EA6BI,kBAAA;EACA,WAAA;EACA,SAAA;AADJ","sourcesContent":[".iframe-wrapper {\n  position: fixed;\n  height: calc(100% - 40px);\n  min-height: 400px;\n  max-height: 1200px;\n  bottom: 20px;\n  right: 20px;\n  z-index: calc(var(--coze-z-index-iframe) + 1);\n  width: 460px;\n  background-color: #fff;\n  border-radius: 8px;\n  box-shadow: 0 6px 8px 0 rgb(29 28 35 / 6%), 0 0 2px 0 rgb(29 28 35 / 18%);\n\n  .loading {\n    position: absolute;\n    top: 200px;\n    left: 50%;\n    transform: translateX(-50%);\n  }\n\n  .coze-iframe {\n    width: 100%;\n    height: 100%;\n    background: #fff;\n    border: none;\n    border-radius: 8px;\n  }\n\n  .close-btn {\n    position: absolute;\n    right: 16px;\n    top: 16px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"iframe-wrapper": "fe3173ed6eda3342aedf",
	"iframeWrapper": "fe3173ed6eda3342aedf",
	"loading": "f9fba408aa15aa07be96",
	"coze-iframe": "eb27e8d345a6df189413",
	"cozeIframe": "eb27e8d345a6df189413",
	"close-btn": "d8ba2c365ec31bc5789a",
	"closeBtn": "d8ba2c365ec31bc5789a"
};
export default ___CSS_LOADER_EXPORT___;
