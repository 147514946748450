import { devtools, subscribeWithSelector } from 'zustand/middleware';
import { create } from 'zustand';
import { produce } from 'immer';

import { isDevAlias } from '@/util/env';
import { type ImagePreview } from '@/types';

interface ClientState {
  chatVisible: boolean;
  iframe?: HTMLIFrameElement;
  iframeLoaded: boolean;
  imagePreview: ImagePreview;
}

interface ClientAction {
  setIframe: (el: HTMLIFrameElement) => void;
  setIframeLoaded: (loaded: boolean) => void;
  setChatVisible: (visible: boolean) => void;
  setImagePreview: (recipe: (preview: ImagePreview) => void) => void;
}

export type ClientStateAction = ClientState & ClientAction;

export const defaultState: ClientState = {
  chatVisible: false,
  iframeLoaded: false,
  imagePreview: {
    url: '',
    visible: false,
  },
};

export const createGlobalStore = () =>
  create<ClientStateAction>()(
    devtools(
      subscribeWithSelector((set, get) => ({
        ...defaultState,
        setIframe: element => {
          set({
            iframe: element,
          });
        },
        setChatVisible: visible => {
          set({
            chatVisible: visible,
          });
        },
        setIframeLoaded: loaded => {
          set({
            iframeLoaded: loaded,
          });
        },
        setImagePreview: recipe =>
          set(
            produce<ClientState>(draft => {
              recipe(draft.imagePreview);
            }),
          ),
      })),
      {
        enabled: isDevAlias,
        name: 'sdkChatApp.global',
      },
    ),
  );
