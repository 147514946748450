import { type CSSProperties, type FC, useEffect, useRef } from 'react';

import { useShallow } from 'zustand/react/shallow';
import cs from 'classnames';
import { UIButton, Spin } from '@flow-infra/bot-semi';
import { IconClose } from '@douyinfe/semi-icons';

import { useGlobalStore } from '@/store/context';

import { getUrlProtocol } from './util';

import styles from './index.module.less';

export interface CozeIframeProps {
  style?: CSSProperties;
  className?: string;
  onClose?: () => void;
  iframeParamsSearch: string;
}

export const CozeIframe: FC<CozeIframeProps> = ({
  style,
  className,
  onClose,
  iframeParamsSearch,
}) => {
  const iframeRef = useRef<HTMLIFrameElement>();
  const { setIframe, setIframeLoaded, iframeLoaded } = useGlobalStore(
    useShallow(s => ({
      setIframe: s.setIframe,
      setIframeLoaded: s.setIframeLoaded,
      iframeLoaded: s.iframeLoaded,
    })),
  );

  useEffect(() => {
    setIframe(iframeRef.current);
  }, []);

  return (
    <div className={cs(styles.iframeWrapper, className)} style={style}>
      {!iframeLoaded && (
        <Spin spinning size="large" wrapperClassName={styles.loading} />
      )}
      <UIButton
        className={styles.closeBtn}
        icon={<IconClose />}
        theme="borderless"
        type="tertiary"
        size="small"
        onClick={() => onClose?.()}
      />
      <iframe
        style={{ display: iframeLoaded ? 'block' : 'none' }}
        className={styles.cozeIframe}
        src={getUrlProtocol(iframeParamsSearch)}
        ref={iframeRef}
        onLoad={() => {
          setIframeLoaded(true);
        }}
      />
    </div>
  );
};
