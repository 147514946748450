// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../common/temp/node_modules/.pnpm/css-loader@6.10.0_@rspack+core@0.6.0_webpack@5.89.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../common/temp/node_modules/.pnpm/css-loader@6.10.0_@rspack+core@0.6.0_webpack@5.89.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".d4b3c09e1c077a26ab0d {\n  position: fixed;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  z-index: calc(var(--coze-z-index-iframe) + 1);\n  background-color: #fff;\n}\n.d4b3c09e1c077a26ab0d .b0ad65a3ee22d23a3cec {\n  position: absolute;\n  top: 200px;\n  left: 50%;\n  transform: translateX(-50%);\n}\n.d4b3c09e1c077a26ab0d .dc9a2a7460323d8bade1 {\n  width: 100%;\n  height: 100%;\n  background: #fff;\n  border: none;\n}\n.d4b3c09e1c077a26ab0d .a55230df900a1ec98f1a {\n  position: absolute;\n  right: 12px;\n  top: 24px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/coze-iframe/mobile/index.module.less"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,MAAA;EACA,QAAA;EACA,SAAA;EACA,OAAA;EACA,6CAAA;EACA,sBAAA;AACF;AARA;EAUI,kBAAA;EACA,UAAA;EACA,SAAA;EACA,2BAAA;AACJ;AAdA;EAiBI,WAAA;EACA,YAAA;EACA,gBAAA;EACA,YAAA;AAAJ;AApBA;EAwBI,kBAAA;EACA,WAAA;EACA,SAAA;AADJ","sourcesContent":[".iframe-wrapper-mobile {\n  position: fixed;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  z-index: calc(var(--coze-z-index-iframe) + 1);\n  background-color: #fff;\n\n  .loading {\n    position: absolute;\n    top: 200px;\n    left: 50%;\n    transform: translateX(-50%);\n  }\n\n  .coze-iframe {\n    width: 100%;\n    height: 100%;\n    background: #fff;\n    border: none;\n  }\n\n  .close-btn {\n    position: absolute;\n    right: 12px;\n    top: 24px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"iframe-wrapper-mobile": "d4b3c09e1c077a26ab0d",
	"iframeWrapperMobile": "d4b3c09e1c077a26ab0d",
	"loading": "b0ad65a3ee22d23a3cec",
	"coze-iframe": "dc9a2a7460323d8bade1",
	"cozeIframe": "dc9a2a7460323d8bade1",
	"close-btn": "a55230df900a1ec98f1a",
	"closeBtn": "a55230df900a1ec98f1a"
};
export default ___CSS_LOADER_EXPORT___;
