import {
  type ReactNode,
  createContext,
  useRef,
  type FC,
  useContext,
} from 'react';

import { useStoreWithEqualityFn } from 'zustand/traditional';
import { shallow } from 'zustand/shallow';

import { type WebChatClient } from '@/client';

import { createIframeStore, type IframeStateAction } from './iframe';
import { type ClientStateAction, createGlobalStore } from '.';

export type ClientStore = ReturnType<typeof createGlobalStore>;
export type IframeStore = ReturnType<typeof createIframeStore>;
export const GlobalStoreContext = createContext<{
  globalStore: ClientStore;
  iframeStore: IframeStore;
}>(undefined);

export const GlobalStoreProvider: FC<{
  children: ReactNode;
  client: WebChatClient;
}> = ({ children, client }) => {
  const storeRef = useRef<ClientStore>();
  const iframeStoreRef = useRef<IframeStore>();

  if (!storeRef.current) {
    storeRef.current = createGlobalStore();
  }
  if (!iframeStoreRef.current) {
    iframeStoreRef.current = createIframeStore(client);
  }
  return (
    <GlobalStoreContext.Provider
      value={{
        globalStore: storeRef.current,
        iframeStore: iframeStoreRef.current,
      }}
    >
      {children}
    </GlobalStoreContext.Provider>
  );
};

export const useGlobalStore: <T>(
  selector: (store: ClientStateAction) => T,
) => T = selector => {
  const store = useContext(GlobalStoreContext).globalStore;
  return useStoreWithEqualityFn(store, selector, shallow);
};

export const useIframeStore: <T>(
  selector: (store: IframeStateAction) => T,
) => T = selector => {
  const store = useContext(GlobalStoreContext).iframeStore;
  return useStoreWithEqualityFn(store, selector, shallow);
};
