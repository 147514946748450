export interface PostMessage<T = unknown> {
  event: PostMessageEvent;
  chatStoreId: string;
  payload: T;
}

export enum PostMessageEvent {
  ImageClick = 'ImageClick',
}

export const postMessageUtil = {
  isImageClick(
    msg: PostMessage<Partial<PostMessageData['ImageClick']>>,
  ): msg is PostMessage<PostMessageData['ImageClick']> {
    return msg.event === PostMessageEvent.ImageClick && !!msg?.payload?.url;
  },
};

export interface PostMessageData {
  [PostMessageEvent.ImageClick]: {
    url: string;
  };
}
